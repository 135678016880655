import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
// @ts-ignore
import {detectLocale} from "../../services/utils";
import {IProps} from "../../types/homePageTypes";
import SSRAccount from "./FooterAccount/index";
import {useRouter} from "next/router";
import {setWindowSize} from "../../store/general";
import {FormattedMessage} from "react-intl";
import Link from "next/link";

const FooterContacts = dynamic(() => import('./FooterContacts'))
const FooterNewsletter = dynamic(() => import('./FooterNewsletter'))
const ToTop = dynamic(() => import('./ToTop'))
const MobileFooterNavs = dynamic(() => import("../mobile/MobileFooterNavs"))

const FooterAccount = dynamic(() => import('./FooterAccount'))

function HotelFooter({pageProps}: { pageProps: IProps }) {
    const dispatch = useDispatch()
    const router = useRouter();
    const siteLocale = useSelector((state: IState) => state.locale.code)
    const windowSize = useSelector((state: IState) => state.general.windowSize)
    const footerContent = detectLocale(useSelector((state: IState) => state.general.coreConfigs.general_content_footer_footer_content), siteLocale)?.value
    const [isTablet, setIsTablet] = useState(false)

    useEffect(() => {
        if (!windowSize) dispatch(setWindowSize(window.innerWidth))
        setIsTablet(windowSize <= 991)
    }, [windowSize]);

    return (
        <>
            <div className="site-footer" style={{
                minHeight: 225
            }}>
                <div className="footer-container container">
                    <div className="footer-body" style={{minHeight: 134, width: 100 + "%"}}>
                        <div className="footer-first-column">
                            {router.asPath === "/" ? (
                                <FooterAccount
                                    pageProps={pageProps}
                                />
                            ) : (
                                <SSRAccount/>
                            )}
                        </div>

                        <div className="footer-newsletter-hide">
                            <FooterContacts pageProps={pageProps}/>
                        </div>

                        <div className="newsletter-block" style={{display: pageProps.showSubscription}}>
                            <FooterNewsletter/>
                        </div>
                    </div>
                    <div className="copyright">
                        <p>{router.asPath === "/" ? pageProps.footerContent?.value : footerContent}</p>
                        <div style={{display: "flex", gap: "5px", justifyContent: "center"}}>
                            <FormattedMessage
                                id="developed"
                                defaultMessage="Developed by"
                            />
                            <Link href="https://www.fidem.am/">
                                <a style={{
                                    color: "#0645AD",
                                    // fontWeight: "600",
                                    textDecoration: "underline",
                                    cursor: "pointer"
                                }}
                                   target="_blank"
                                >
                                    Fidem.am
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
                <ToTop/>
            </div>
            {isTablet && <div>
                <MobileFooterNavs/>
            </div>}
        </>

    );
}

export default memo(HotelFooter);
