import React, {FC, useEffect, useState} from "react";
import Link from "next/link";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {IMenuList, IState} from "../../../types/state";
import {IFooterAccount as IProps} from ".././FooterNewsLetter/types";
import { LogoSmallSvg } from "../../../svg";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {apiImageUrl} from "../../../helper";
const SSRAccount: FC = () => {
    // TODO!!!! without SSR
    const [footerLinks, setFooterLinks] = useState<Array<IMenuList>>([]);
    const hasTracking = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.sales_tracking_tracking_active?.value)))
    const hasContact = Boolean(+(useSelector((state: IState) => state.general.coreConfigs.theme_contact_us_active?.value)));
    const menuList = useSelector((state: IState) => state.general.menuList)
    const locale = useSelector((state: IState) => state.locale)
    const domain = useSelector((state: IState) => state.general.domain)
    const cacheVersion = useSelector((state: IState) => state.general.cashVersion)

    useEffect(() => {
        setFooterLinks(menuList?.filter((item) => item.type === "footer"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    return (
        <>
            <div className="header__logo">
                {
                    domain ? (
                            <Link href="/">
                                <a aria-label="go to home page">
                                    <LazyLoadImage
                                        alt="mobile-logo"
                                        src={`/storage/${domain}/configuration/logo/logo.webp?v=${cacheVersion}`}
                                        onError={(e) => {
                                            // @ts-ignore

                                            e.target.src = `${apiImageUrl}/vendor/webkul/ui/assets/images/logo.svg?v=${cacheVersion}`;
                                        }}
                                    />
                                </a>
                            </Link>
                        )
                        : (
                            <Link href="/">
                                <a aria-label="go to home page">
                                    <LazyLoadImage
                                        alt=""
                                        width={150}
                                        height={45}
                                        src={`${apiImageUrl}/vendor/webkul/ui/assets/images/logo.svg`}
                                    />
                                </a>
                            </Link>
                        )
                }
            </div>

            <div className="footer_text">
                <p>
                    <FormattedMessage
                        id="footer.text"
                        defaultMessage="Lorem ipsum dolor sit amet consectetur. Proin nunc proin et sed. "
                    />
                </p>

            </div>

            {hasContact && (<div className="trackingNumber-fms">
                <Link href="/contact">
                    <a>
                        <FormattedMessage
                            id="contact_with_us"
                            defaultMessage="Contact With Us"
                        />
                    </a>
                </Link>
            </div>)}

        </>
    );
}

export default SSRAccount;